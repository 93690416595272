.token {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.token object, .token img {
  width: 100%;
  border-radius: 5px;
}
