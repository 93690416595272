.tag {
  height: 18px;
  background: white;
  border: none;
  border-radius: 4px;
  color: var(--lighter);
  font-weight: bold;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 14px;
}

.content {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 2px;
  margin-top: 2px;
}