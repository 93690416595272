:root {
  --shadow: #0e1310;
  --dark: #1D1D1D;
  --lighter: #343434;
  --lighterer: #575757;
  --lightererer: #7c7c7c;
  --primary: white;
}

.App {
  background-color: var(--dark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

@media (max-width: 450px) {
  h1 {
    font-size: 20px !important;
  }
}

h1 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0px;
}

button {
  cursor: pointer;
  transition: 0.2s;
  font-family: inherit;
}

h2 {
  font-size: 26px;
}

h3 {
  text-align: center;
  font-size: 17px;
  font-weight: 300;
  text-align: left;
}

a {
  color: var(--primary);
  text-decoration: underline;
}

a:hover {
  text-decoration: underline;
}

p {
  margin: 0;
  font-size: 20px;
}

li {
  margin-left: -10px;
  margin-bottom: 10px;
}

.linksContainer a {
  margin-left: 5px;
  margin-right: 5px;
}

.linksContainer a:hover {
  filter: drop-shadow(0px 0px 4px var(--primary));
}

.switchNetworkBtn {
  background: none;
  border: 2px solid #b86500;
  max-width: 400px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 8px;
  color: #b86500;
  font-size: 20px;
  font-weight: bold;
  transition-duration: 0.2s;
}
.switchNetworkBtn:hover {
  background: #b86500;
  color: white;
}

.toolTip {
  position: relative;
  display: inline-block;
}
.toolTip .toolTipText {
  visibility: hidden;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 14px;
  padding: 5px 5px;
  border-radius: 6px;
  bottom: 100%;
  left: 50%;
  margin-left: -50%;
  transform: translateY(-5px);
  position: absolute;
  z-index: 1;
  font-weight: 400;
}
.toolTip:hover .toolTipText {
  visibility: visible;
}
